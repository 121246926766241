*{
  font-family: 'Overpass', sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: #e5e5e5;
  margin: 0;
  font-family: 'Overpass', sans-serif;  
}

code {
  font-family: 'Overpass', sans-serif;
}

/* Hide arrows for number type inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}
